/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://6kzxj2acnrfedjukhkaxyy5ncy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "cdyxdialer",
            "endpoint": "https://h8nl8iv4dk.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "cdyxmaxpromptpay",
            "endpoint": "https://529680z75c.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "cdyxpreprocess",
            "endpoint": "https://bw963v0kva.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "cdyxpromptpay",
            "endpoint": "https://jvrseu0goi.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "e2cif",
            "endpoint": "https://r63r4emrj6.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "e2postcontact",
            "endpoint": "https://lnpjy51ix3.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "importapi",
            "endpoint": "https://qpzkhhqhjf.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "veerabradely",
            "endpoint": "https://41z8zolp66.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:c5e87c7c-95b7-48a2-92d2-b2c20ea89c5f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_xjUUEsP1S",
    "aws_user_pools_web_client_id": "accr4vu9bacbrcv1veocgfsek",
    "oauth": {},
    "aws_content_delivery_bucket": "cdyxlm-prod",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://dwqjfceqvtszf.cloudfront.net",
    "aws_user_files_s3_bucket": "beastimportfiles152321-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
