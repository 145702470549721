import React, { useEffect, useContext, useState } from 'react';
import { makeStyles, Typography, Button, Grid, TablePagination } from "@material-ui/core";
import { EditOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import { Link, useHistory } from "react-router-dom";
import ConfirmDialog from "../components/ConfirmDialog";
import MaterialTable from 'material-table';
import UserContext from '../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as _ from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}));

export default function DialingProfiles() {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [profiles, setProfiles] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getData() {
            setLoading(true);

            const savedProfiles = await API.graphql(graphqlOperation(queries.profileByTenant, { tenant: userContext.tenant }));
            if (savedProfiles && savedProfiles.data) {
                setProfiles(savedProfiles.data.profileByTenant.items);
            }

            setLoading(false);
        }
        if (userContext.tenant) {
            getData();
        }
    }, [userContext.tenant]);

    const confirmDelete = (id) => {
        setToDelete(id);
        setConfirmOpen(true);
    }

    const licensePaging = (props) => {
        return (
            <TablePagination
                count={props.count || 0}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage} />
        )
    }

    async function handleDelete() {
        setConfirmOpen(false);
        const campaignResult = await API.graphql(graphqlOperation(queries.campaignsByTenant, { tenant: userContext.tenant, filter: { and: [{ campaignProfileId: { eq: toDelete } }] } }));
        if (campaignResult.data.campaignsByTenant.items.length > 0) {
            enqueueSnackbar('Cannot delete the profile. Campaigns are using it.', {
                variant: 'error',
                autoHideDuration: 5000
            });
            return;
        }
        const deleteResult = await API.graphql(graphqlOperation(mutations.deleteProfile, { input: { id: toDelete } }));
        console.log(deleteResult);
        //if profile is sucessfull in deleting remove it from the current list.
        if (deleteResult.data) {
            const deletedProfile = deleteResult.data.deleteProfile;
            const curProfiles = [...profiles];
            _.remove(curProfiles, function (e) {
                return e.id === deletedProfile.id;
            });
            setProfiles([...curProfiles]);
        }

    }

    function handleCancelDelete() {
        setConfirmOpen(false);
    }

    return (
        <div className={classes.root}>
            <Grid item container direction="row" justify="space-between" alignItems="flex-end">
                <Typography variant="h4">Profiles</Typography>
                <Button variant="contained" color="primary" component={Link} to="/profile/new">+ Profile</Button>
            </Grid>
            <br></br>
            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                <Grid item>
                    <MaterialTable
                        title=""
                        data={profiles}
                        columns={[
                            { title: 'Name', field: 'name' },
                            { title: 'Scrub: Org DNC', field: 'scrubber.orgDnc' },
                            { title: 'Scrub: Phone Dup.', field: 'scrubber.phoneDup' },
                            { title: 'Scrub: Contact Dup.', field: 'scrubber.contactDup' },
                            // { title: 'Retry: Delay', field: 'retry.minDuration' },
                            // { title: 'Retry: Attempts', field: 'retry.maxAttempts' },
                            // { title: 'Retry: Contacts', field: 'retry.maxContacts' },
                            // { title: 'Retry Vs New', field: 'retry.ratio' }
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 10
                        }}
                        isLoading={loading}
                        components={{
                            Pagination: licensePaging
                        }}
                        actions={[
                            {
                                icon: () => (<EditOutlined color="primary" />),
                                tooltip: 'Edit Profile',
                                onClick: (event, rowData) => {
                                    history.push(`/profile/${rowData.id}`);
                                }
                            },
                            {
                                icon: () => (<DeleteOutlineOutlined color="primary" />),
                                tooltip: 'Delete Profile',
                                onClick: (event, rowData) => {
                                    confirmDelete(rowData.id)
                                }
                            }
                        ]}
                        onRowClick={(event, rowData) => {
                            history.push(`/profile/${rowData.id}`);
                        }}
                    />
                </Grid>
            </Grid>
            <ConfirmDialog
                open={confirmOpen}
                value={toDelete}
                onConfirm={handleDelete}
                onCancel={handleCancelDelete}
                confirmTxt="Yes">
                <Typography>This profile will be permanently deleted.</Typography>
            </ConfirmDialog>
        </div>
    )
}